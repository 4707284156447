h1, h2, h3, h4 {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

ul, ol, dl, dd {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

label {
  display: block;
}

time {
  display: block;
}

input, textarea, button, select {
  border: none;
  font: inherit;
}

figure,
blockquote {
  margin: 0;
}